@import "../../styles/variables.scss";

#lyubomira-and-aleksandur{

  .parallax {
    color: aliceblue;
  }

  .parallax-inner {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  .parallax-engaged {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: -1;
      background: url("./images/desktop1.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      @media screen and (max-width: $screen-sm) {
        background: url("./images/mobile1.webp") center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }
  }

  .parallax-quotes {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: -1;
      background: url("./images/desktop3.webp") center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      @media screen and (max-width: $screen-sm) {
        background: url("./images/mobile3.webp") center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }
  }

  .parallax-our-story {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      height: 100vh;
      left: 0;
      right: 0;
      z-index: -3;
      background: url("./images/desktop2.webp") center bottom;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      left: 0;
      background-size: cover;

      @media screen and (max-width: $screen-sm) {
        left: -5%;
        background: url("./images/mobile2.webp") bottom center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }

    .parallax-inner {
      padding-top: 15%;
      padding-bottom: 15%;
    }
  }
}