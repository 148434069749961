@import "../../styles/variables.scss";

#abiturient-lyuboslav {
.section-left-image {
  min-height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 1;

    @media (max-width: $screen-sm) {
      opacity: 0.2;
    }
  }
}