@import "../../styles/variables.scss";

@keyframes slideFromTop {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#lyubomira-and-aleksandur {
  .navbar {
    z-index: 2000;
    position: relative;

    &-container {
      padding: 0;
      display: flex;

      li {
        list-style-type: none;
        padding: 16px 18px 0px;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        color: var(--primary-color);
        position: relative;
      }

      li a::before {
        content: "";
        width: 0;
        height: 5px;
        background-color: var(--primary-color);
        position: absolute;
        top: 120%;
        left: 0;
        transition: all 0.5s;
      }

      li a::after {
        content: "";
        width: 0;
        height: 5px;
        background-color: var(--secondary-color);
        position: absolute;
        top: 120%;
        right: 0;
        transition: all 0.5s;
      }

      li a:hover::before {
        width: 50%;
        transform: translateX(100%);
      }

      li a:hover::after {
        width: 50%;
        transform: translateX(-100%);
      }
    }

    .confirm-link-container {
      padding-top: 16px;

      .confirm-link {
        background: white;
        padding: 8px 16px;
        border-radius: 8px;
      }
    }
  }

  .navbar-container-sandwich-menu {

    .sandwich-menu {
      font-size: 26px;
      position: absolute;
      top: $base-offset * 2;
      right: $base-offset * 2;
      padding: 6px;
      background: #ffffff96;
      border-radius: 8px;
      color: var(--link-color);
      z-index: 1999;

      &:hover {
        opacity: 0.8;
      }

      &-content {
        position: absolute;
        z-index: 2000;
        top: 70px;
        right: 16px;
        background: var(--primary-color);
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        border-radius: 8px;

        hr {
          border-top: 1px solide var(--link-color);
        }

        a {
          color: white;
          text-decoration: none;
          padding: 16px;
          cursor: pointer;

          &:hover {
            color: var(--primary-secondary);
          }
        }
      }

      &-opened {
        color: white;
        z-index: 2000;
        padding: 0px;
        background: none;
      }
    }
  }

  .sandwich-menu {
    font-size: 24px;
    top: $base-offset * 2;
    right: $base-offset * 3;
    position: absolute;
    color: var(--link-color);
    z-index: 1999;

    &-content {
      position: absolute;
      z-index: 2000;
      top: 0px;
      right: 0px;
      background: var(--primary-color);
      animation-name: slideFromTop;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      border-radius: 8px;

      hr {
        border-top: 1px solide var(--link-color);
      }

      a {
        color: white;
        text-decoration: none;
        padding: 16px;
      }
    }

    &-opened {
      color: white;
      z-index: 2000;
      padding: 0px;
      background: none;
    }
  }
}