@import "../../styles/variables.scss";

#abiturient-lyuboslav {

  .fold-intro-image {
    max-width: 40%;
    height: auto;
    margin: 0 auto;
    margin-bottom: -90px;

    @media screen and (min-width: $screen-lg) {
      margin-top: -24px;
    }

    @media screen and (max-width: $screen-lg) {
      max-width: 80%;
      margin-bottom: -70px;
    }

    @media screen and (max-width: $screen-md) {
      max-width: 80%;
      margin-bottom: -70px;
    }

    @media screen and (max-width: $screen-xs) {
      max-width: 100%;
      margin-bottom: -60px;
    }
     
  }
  .bg-fold-left {
    position: absolute;
    left: 0;
    top: -66px;
    width: 40%;
    max-width: 700px;
    height: auto;

    @media screen and (max-width: $screen-xs) {
      top: 0px;
      width: 48%;
    }
  }

  .bg-fold-bottom {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    opacity: 0.4;
    max-width: 2000px;
    margin: 0 auto;
  }

  .fold {
    min-height: 600px;
  }
}