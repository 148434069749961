@import "../../styles/variables.scss";

#abiturient-lyuboslav {

  .content-custom-margin {
    margin-top:-8px;
    
  }
  .bg-engaged-section {
    background-color: var(--third-color);
    background-image: url("./images/bg-fold-clouds.webp");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    overflow: hidden;

    @media screen and (max-width: $screen-lg) {
      background-size: cover;
    }
  }

  .our-image {
    width: 100%;
    height: auto;
    max-width: 300px;
  }

  .our-image-container {
    margin-top: -200px;
    margin-left: -24px;
    position: sticky;
    top: 0px;
    align-self: flex-start;

    @media (max-width: $screen-sm) {
      width: 98%;
      margin-left: -16px;
      margin-top: -100px;
      top: 50%;
    }
  }

  .our-image-footer {
    width: 100px;
    height: auto;
    margin: 16px auto;
    cursor: pointer;
    transition-duration: 0.8s;
    transition-property: transform;

    &:hover {
      transform: rotate(30deg);
    }
  }

  .bride-steal {
    &--bg {
      background: #acb4a0;
    }

    img {
      width: 100%;
      max-width: 600px;
      transform: rotate(-6deg);
      margin-bottom: -$base-offset * 4;
    }
  }

  .church {
    &--bg {
      background: #ffe9c0;
    }
  }

  .slider div {
    border-radius: 16px;
  }

  .slider>div {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .garden {
    &--bg {
      background: #f0ebec;
    }
  }

  .restaurant {
    &--bg {
      background: #c48b67;
    }
  }

  .transport {
    &--bg {
      background: #e9eae0;
    }
  }

  .map-location {
    border-radius: 8px;
    overflow: hidden;
  }

  .bulgarian-rose {
    width: 100px;
    height: auto;
    position: absolute;
    z-index: 11;
    bottom: -$base-offset * 4;
    right: -$base-offset * 4;

    @media (max-width: $screen-sm) {
      width: 60px;
      height: auto;
      right: -14px;
      bottom: -$base-offset * 3;
    }
  }

  .slider-mobile {
    @media (max-width: $screen-xs) {
      overflow-x: hidden;
    }
  }
}
