@import "../../styles/variables.scss";

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes heartbeat {
  0% {
    transform: rotate(45deg) scale(1);
  }
  25% {
    transform: rotate(45deg) scale(1);
  }
  30% {
    transform: rotate(45deg) scale(1.2);
  }
  50% {
    transform: rotate(45deg) scale(1.1);
  }
  70% {
    transform: rotate(45deg) scale(1.2);
  }
  100% {
    transform: rotate(45deg) scale(1);
  }
}

#lyubomira-and-aleksandur{
.counter {
  background: linear-gradient(-45deg, var(--primary-color), var(--primary-color), var(--secondary-color));
  background-size: 200% 200%;
  animation: gradient 10s ease infinite;

  .counter-text-shadow {
    text-shadow: 0 0 3px var(--primary-color);
  }

  &-text {
    font-size: 100px;

    @media (max-width: $screen-sm) {
      font-size: 42px;
    }
  }
  container {
    min-height: 50vh;
  }
}

.heart {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #980227;
  transform: rotate(45deg);
  animation: heartbeat 1.4s linear infinite;
  top: $base-offset * 8;

  @media (max-width: $screen-sm) {
    width: 40px;
    height: 40px;
    top: $base-offset * 4;
  }
}

.heart:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #980227;
  transform: translateY(-50%);
  border-radius: 50%;
}

.heart:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #980227;
  transform: translateX(-50%);
  border-radius: 50%;
}
}
