@import "../../styles/variables.scss";

#lyubomira-and-aleksandur{
  .text-shadow {
    text-shadow: 1px 1px 0px rgba(5, 71, 154, 0.8);
  }
  
  .bg-fold {
    background-color: white;
    background-image: url("./images/bg-fold-rose.webp");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover; 

    @media screen and (max-width: $screen-md) {
      background-size: cover;
    }
  }

  .bg-fold-left {
    position: absolute;
    left: -8px;
    top: -10%;
    width: 20%;
    max-width: 300px;
    height: auto;
    z-index: 2;

    @media screen and (max-width: $screen-md) {
      top: 0px;
      width: 44%;
    }
  }

  .bg-fold-left-back {
    position: absolute;
    left: 0px;
    top: -74px;
    width: 22%;
    max-width: 320px;
    height: auto;
    z-index: 1;

    @media screen and (max-width: $screen-xs) {
      width: 40%;
    }
  }

  .bg-fold-right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    max-width: 500px;
    height: auto;

    @media screen and (max-width: $screen-xs) {
      width: 60%;
    }
  }


  .text-fold {
    background: linear-gradient(-90deg, #00dffc 5px, #0000 0) 10px 0,
      linear-gradient(#00dffc 0 0) 0 0;
    background-size: calc(var(--n) * 1ch) 200%;
    -webkit-background-clip: padding-box, text;
    background-clip: padding-box, text;
    background-repeat: no-repeat;
    animation: b 0.7s infinite steps(1),
      t calc(var(--n) * 0.3s) steps(var(--n)) forwards;
  }

  .fold {
    min-height: 600px;
  }
}